import React from 'react';
import cl from './_MainPage.module.scss'
import {APPLICATION_URL} from '../../core/service/urls'

import LogoR from '../../core/ui/logo/r/LogoR'
import Text64B from '../../core/ui/text/64/Text64B';
import Text32M from '../../core/ui/text/32/Text32M';
import MainBackground from './core/components/background/MainBackground';
import LinkBlue from '../../core/ui/link/blue/LinkBlue';
import Text20M from "../../core/ui/text/20/Text20M";
import BrandSupport from "../../core/static/img/brand-suppport.png";

const MainPage = () => {
	return (
		<>
			<div className={cl.content}>
				<div className={cl.top}>
					<LogoR className={cl.logo} />
					<div className={cl.right}>
						<Text64B className={cl.title}>СДЕЛАНО В КОРОЛËВЕ</Text64B>
						<Text32M className={cl.description}>Онлайн-платформа продвижения локальных предпринимателей Подмосковья</Text32M>
						<LinkBlue className={cl.button} to={APPLICATION_URL} title='Стать партнёром' />
					</div>
				</div>
				<div className={cl.bottom}>
					<img src={BrandSupport} alt={'BrandSupport'} className={cl.img}/>
					<Text20M className={cl.brandHint}>Проект создан при поддержке Фонда содействия инновациям в рамках федерального проекта «Платформа университетского технологического предпринимательства»</Text20M>
				</div>
			</div>

			<MainBackground />
		</>
	);
};

export default MainPage;